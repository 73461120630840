<template>
  <v-container fluid fill-height class="down-top-padding">
    <v-flex fill-height>
      <v-row>
        <v-col cols="12" sm="12" lg="12">
          <BaseCard :height="'100%'" class="memo-inbox">
            <header-left
              slot="left-toolbar"
              @sentReload="sentReload"
              v-bind:heading="heading"
              v-bind:enableAction="selectedItems.length > 0"
              v-bind:selectedItems="selectedItems"
              v-on:revokeSelected="revokeSelected"
              :filterModal="filterModal"
              @filterModal="setFilterModal"
            />
            <header-right
              slot="right-toolbar"
              @sentReload="sentReload"
              :enableAction="selectedItems.length > 0"
            />
            <p
              v-if="
                (!mySentList || mySentList.length <= 0) &&
                !axiosLoading &&
                !networkError
              "
              class="align-center center-align"
            >
              {{ $t("text.sent-empty") }}
            </p>
            <v-list
              :data-intro="$t('introjs.this-is-sent-list')"
              two-line
              class="pa-1"
              v-if="!networkError && !axiosLoading && mySentList"
            >
              <template v-for="(item, index) in mySentList">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="$t(`${'label.' + item.header}`)"
                >
                </v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.id_hash"
                  @click.self="showSentDetail(item.id_hash, item.creator_nik)"
                  v-ripple="{ class: `primary--text` }"
                >
                  <v-tooltip bottom v-if="parseInt(item.secretary_flag) === 1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="att-icon mr-2"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ iconSecretary }}
                      </v-icon>
                    </template>
                    <span>{{ $t("tooltip.copy for secretary") }}</span>
                  </v-tooltip>
                  <label class="mr-2 check--blank" v-else></label>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-avatar
                        v-on="on"
                        v-bind="attrs"
                        class="d-none d-sm-block mr-2"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        <v-img :src="item.avatar_path"></v-img>
                      </v-list-item-avatar>
                    </template>
                    <span>{{
                      `${$t("text.signed-by")} : ${item.avatar_label}`
                    }}</span>
                  </v-tooltip>

                  <v-list-item-content
                    @click="showSentDetail(item.id_hash, item.creator_nik)"
                  >
                    <v-list-item-title>
                      <span
                        :data-intro="$t('introjs.this-is-sender-name')"
                        class="sub-headline"
                        style="line-height: 1.5rem"
                      >
                        {{ item.title }}
                      </span>
                      <div
                        class="d-inline-flex align-self-center align-center align--vertical-center"
                        :data-intro="$t('introjs.badge-is-tag-for-memo-status')"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-if="parseInt(item.has_attachments) == 1"
                              class="att-icon mx-1"
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              {{ iconAttachment }}
                            </v-icon>
                          </template>
                          <span>{{ $t("tooltip.attachment-exist") }}</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-bind="attrs"
                              v-on="on"
                              v-if="item.tipe === 'sppd'"
                              href="javascript:void(0)"
                              class="label text-white f-12"
                              style="
                                background: linear-gradient(
                                  -135deg,
                                  rgb(24 203 196) 0%,
                                  rgb(62 136 247) 100%
                                );
                              "
                              >{{ $t("badge.sppd") }}</a
                            >
                          </template>
                          <span>{{ $t("tooltip.sppd form") }}</span>
                        </v-tooltip>
                      </div>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :data-intro="$t('introjs.this-is-memo-number-and-title')"
                      class="d-none d-sm-block"
                    >
                      {{
                        $t("label.number") + `: ${cutJudul(item.subtitle, 150)}`
                      }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="d-block d-sm-none">
                      {{ cutJudul(item.subtitle, 150) }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span
                        v-if="!$vuetify.breakpoint.smAndDown"
                        class="text-right"
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                      <span
                        class="text-right"
                        v-else
                        v-on="on"
                        v-bind="attrs"
                        :data-intro="$t('introjs.this-is-sending-date')"
                        @click="showSentDetail(item.id_hash, item.creator_nik)"
                      >
                        {{ $date(item.data_time).format("DD MMM YYYY") }}
                        <br />
                        {{ $date(item.data_time).format("HH:mm") }} WIB
                      </span>
                    </template>
                    <span>{{
                      `${$t("tooltip.sending-at")} ${timeAgo(item.data_time)}`
                    }}</span>
                  </v-tooltip>
                </v-list-item>
              </template>
            </v-list>
            <v-card-text v-if="networkError || axiosLoading || !mySentList">
              <v-skeleton-loader
                class="mx-auto"
                max-width="100%"
                type="list-item-avatar-two-line, list-item-avatar-two-line, list-item-avatar-two-line"
              >
              </v-skeleton-loader>
            </v-card-text>
          </BaseCard>
        </v-col>
      </v-row>
    </v-flex>
    <insert-comment
      v-bind:showCommentDialog="showCommentDialog"
      v-bind:submitOptions="submitOptions"
      v-on:showHideCommentDialog="showHideCommentDialog"
      v-on:commentFilled="commentFilled"
    ></insert-comment>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import headerLeft from "./HeaderLeft";
import HeaderRight from "./HeaderRight";
import { isIntroActive, rowPerPage } from "@/constants/config";
import dayjs from "dayjs";
import "intro.js/minified/introjs.min.css";
import InsertComment from "@/components/commonComponents/InsertComment.vue";

import {
  mdiAttachment,
  mdiStarCircle,
  mdiIncognito,
  mdiBriefcaseCheck,
  mdiClockFast,
  mdiSpeedometerSlow,
  mdiSpeedometerMedium,
  mdiSpeedometer,
  mdiCogTransfer,
  mdiBackupRestore,
  mdiReplyCircle,
  mdiFileStarOutline,
} from "@mdi/js";

export default {
  components: {
    headerLeft,
    HeaderRight,
    InsertComment,
  },
  data: () => ({
    iconAttachment: mdiAttachment,
    iconSecretary: mdiFileStarOutline,
    iconUrgent: mdiClockFast,
    iconBom: mdiStarCircle,
    iconSecret: mdiIncognito,
    iconPermit: mdiBriefcaseCheck,
    iconSlow: mdiSpeedometerSlow,
    iconMedium: mdiSpeedometerMedium,
    iconFast: mdiSpeedometer,
    iconDirect: mdiCogTransfer,
    iconRevoke: mdiBackupRestore,
    iconReply: mdiReplyCircle,
    selectedItems: [],
    isCheckedAll: false,
    showCommentDialog: false,
    submitOptions: undefined,
    comment: undefined,
    filterModal: false,
  }),
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return "";
      }
      return dayjs(date).fromNow();
    },
  },
  computed: {
    ...mapState(["isMobile", "networkError"]),
    ...mapState("user", ["currentUser"]),
    ...mapState("sent", [
      "sentList",
      "axiosLoading",
      "filterParams",
      "selectAllSent",
    ]),
    currUserNik() {
      if (!this.currentUser.nik) return null;
      return this.currentUser.nik;
    },
    mySentList() {
      if (!this.sentList) return [];
      return this.sentList.data;
    },
    memoIds() {
      if (!this.sentList) return [];
      return this.sentList.data.map(({ id_hash }) => id_hash);
    },
    heading() {
      if (this.$route.name === "mysppd") return this.$t("card.my sppd");
      return this.$t("card.issued sppd");
    },
    currRouteName() {
      return this.$route.name;
    },
  },
  beforeDestroy() {
    this.setFilterParams({
      sortBy: "data_time",
      sortByDesc: "desc",
      perPage: rowPerPage,
      currentPage: 1,
      type: "issued_sppd",
      only: "",
      filter: "",
      from: 1,
      to: 50,
      lastPage: 1,
    });
  },
  mounted() {
    if (isIntroActive === true) {
      const introJS = require("intro.js");
      setTimeout(() => {
        introJS
          .introJs()
          .setOptions({
            exitOnOverlayClick: false,
          })
          .start();
      }, 2000);
    }
  },
  created() {
    this.sentReload(this.$route.name);
  },
  watch: {
    selectAllSent(val) {
      if (this.selectedItems == this.memoIds) return;

      this.setSelectAll(false);
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      if (val === true) {
        this.selectedItems = this.addToArray(this.selectedItems, this.memoIds);
      }
      this.updateCheckAll();
    },
    currRouteName(val, oldval) {
      if (oldval === val) return;
      this.sentReload(val);
    },
  },
  methods: {
    ...mapActions("sent", [
      "loadSent",
      "loadSentX",
      "loadSentXNusantara",
      "emptySent",
      "setFilterParams",
      "setAxiosLoading",
      "setSelectAll",
    ]),
    showSentDetail(id, creatorNik) {
      console.log(this.$route.name);
      let detailPage = "issuedsppd.detailmar";
      if (this.$route.name === "mysppd") {
        if (this.currUserNik === creatorNik) {
          detailPage = "mysppd.detailmac";
        } else {
          detailPage = "mysppd.detailmar";
        }
      } else {
        if (this.currUserNik === creatorNik) {
          detailPage = "issuedsppd.detailmac";
        } else {
          detailPage = "issuedsppd.detailmar";
        }
      }

      setTimeout(() => {
        //beri jeda untuk animasi ripple
        return this.$router.push({
          name: detailPage,
          params: { id: id },
        });
      }, 300);
    },
    sentReload(routeName) {
      // const routeName = this.$route.name;
      // this.setFilterParams(this.filterParams);
      this.setSelectAll(false);
      //remove dulu selected item
      this.selectedItems = this.removeFromArray(
        this.selectedItems,
        this.memoIds
      );

      this.emptySent();
      let tipe = "issued_sppd";

      if (routeName === "mysppd") tipe = "my_sppd";

      this.setFilterParams({
        sortBy: "data_time",
        sortByDesc: "desc",
        perPage: rowPerPage,
        currentPage: 1,
        type: tipe,
        only: "unread,pending,delegated,has_feedback",
        filter: "",
        from: 0,
        to: 0,
        lastPage: 1,
      });

      this.loadSent(this.filterParams);

      // setTimeout(() => {
      //   this.overlay = false;
      // }, 3000);
    },
    updateCheckAll() {
      if (
        this.selectedItems.length == this.memoIds.length &&
        this.selectedItems.length > 0
      ) {
        this.setSelectAll(true);
      } else {
        this.setSelectAll(false);
      }
    },
    cutJudul(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    cutIsi(str, strLen) {
      if (this._deviceType() == "smartphone") {
        return this.strCutStripped(str, strLen);
      } else {
        return this.strCutStripped(str, strLen);
      }
    },
    isUrgent(val) {
      let tagList = ["urgent"];
      return tagList.includes(val);
    },
    isSecret(val) {
      let tagList = "rahasia";
      return tagList.includes(val);
    },
    showHideCommentDialog(val) {
      this.showCommentDialog = val;
    },
    commentFilled(val) {
      if (!val) return;
      this.comment = val;
      // this.terminateSelected();
    },
    revokeSelected() {
      if (!this.selectedItems) return;
      if (this.selectedItems.length > 1) {
        return this.$dialog.error({
          text: this.$t("msgbox.cannot-revoke-more-than-one-draft-at-a-time"),
          title: this.$t("msgbox.revoke-sent-memo"),
          persistent: true,
        });
      }
      if (!this.comment) {
        this.submitOptions = {
          btnText: "button.revoke",
          btnIcon: this.iconRevoke,
          commentDesc: this.$t("text.please-fill-revoke-comment-or-reason"),
        };
        return (this.showCommentDialog = true);
      }
    },
    setFilterModal(val) {
      this.filterModal = val;
    },
  },
};
</script>
