<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <label v-if="isPjpDir" class="avoore-checkbox path d-block mr-2" v-bind:class="{ 'theme--dark': $vuetify.theme.dark }"
      for="select-all-sent">
      <input type="checkbox" class="d-inline-flex" v-model="selectAllSent" id="select-all-sent" />
      <svg viewBox="0 0 21 21">
        <path
          d="M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186">
        </path>
      </svg>
    </label>

    <v-toolbar-title>
      {{ heading }}
    </v-toolbar-title>

    <v-dialog v-model="dialog" persistent width="600">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t('pagetitle.filter data') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete data-hintposition="middle-middle" data-position="bottom-left-aligned" hide-details="true"
                  v-model="filterValue.memoType" v-bind:disabled="isUpdating" v-bind:items="memoTypeList"
                  v-bind:menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: true,
                  }" hide-selected deletable-chips small-chips v-bind:search-input.sync="searchInput"
                  v-on:change="searchInput = ''" chips color="blue-grey lighten-2" v-bind:label="$t('label.memo type')"
                  item-text="title" item-value="value" multiple clearable>
                  <template v-slot:selection="data">
                    <v-chip v-bind="data.attrs" v-bind:input-value="data.selected" close @click="data.select"
                      @click:close="remove(data.item)">
                      {{ data.item.title }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content v-html="data.item.title">
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*{{ $t('text.by default all are checked') }}</small>
        </v-card-text>
        <v-card-actions class="d-flex justify-space-between bg-surface-variant">
          <div>
            <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
              {{ $t('button.close') }}
            </v-btn>
          </div>
          <div>
            <v-btn class="ma-1" color="blue-darken-1" variant="text" @click="resetFilter()">
              {{ $t('button.reset') }}
            </v-btn>
            <v-btn class="ma-1" color="blue-darken-1" variant="text" @click="doFilter()">
              {{ $t('button.filter') }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!enableAction" class="ml-md-4 ml-sm-0" v-bind="attrs" v-on="on" text icon
          color="blue lighten-2 ml-3" @click="sentReload">
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="enableAction && selectedItems.length === 1" class="ml-sm-0 ml-2" v-bind="attrs" v-on="on" text icon
          color="purple lighten-2 ml-3" @click="revokeSelected">
          <v-icon>{{ iconRevoke }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.revoke-selected-memo") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="!enableAction" v-bind="attrs" v-on="on" text icon color="blue lighten-2 ml-3"
          @click="dialog = true">
          <v-icon>{{ iconFilter }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.menu-filter") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-show="enableAction" class="ml-sm-0" v-bind="attrs" v-on="on" text icon color="purple lighten-2 ml-3"
          @click="sentReload">
          <v-icon>{{ iconArchive }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.move-to-archive") }}</span>
    </v-tooltip>

    <v-menu v-if="$vuetify.breakpoint.smAndDown && !enableAction" v-model="showSearch" :close-on-content-click="false"
      :nudge-width="300" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text icon color="blue lighten-2" class="d-flex d-sm-none" v-bind="attrs" v-on="on">
          <v-icon>{{ iconSearch }}</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-col cols="12" sm="12" md="12">
          <v-text-field :label="$t('label.title')" outlined v-model="searchQuery" dense clearable
            hide-details="true"></v-text-field>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="showSearch = false">{{
            $t("button.close")
          }}</v-btn>
          <v-btn color="primary" text @click="applyQuery">{{
            $t("button.search")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <v-tooltip bottom v-if="!$vuetify.breakpoint.smAndDown && !enableAction">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-on="on" v-bind="attrs" v-model="searchQuery" :label="$t('label.search')"
          :placeholder="$t('label.title')" outlined dense clearable hide-details="true" class="ml-2 search-input"
          :class="{ 'min-w-300px': searchQuery }"></v-text-field>
      </template>
      <span v-if="$route.name === 'sentx'">{{ $t("tooltip.type the title or recipient name") }}</span>
      <span v-else>{{ $t("tooltip.type the title") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  mdiReload,
  mdiDotsVertical,
  mdiEmailSearchOutline,
  mdiFilterMenuOutline,
  mdiArchiveArrowDownOutline,
  mdiBackupRestore,
  mdiTuneVariant,
} from "@mdi/js";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    selectedItems: {
      type: [Object, Array]
    },
    filterModal: {
      type: Boolean
    }
  },
  data: () => ({
    searchInput: "",
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconSearch: mdiEmailSearchOutline,
    iconFilter: mdiFilterMenuOutline,
    iconRevoke: mdiBackupRestore,
    iconArchive: mdiArchiveArrowDownOutline,
    iconFilterActive: mdiTuneVariant,
    showSearch: false,
    searchQuery: "",
    isUpdating: false,
    memoTypeList: [
      { title: 'Memo Biasa', value: 'biasa' },
      { title: 'Memo BOM', value: 'bom' },
      { title: 'Memo Izin Prinsip', value: 'izin_prinsip' },
      { title: 'Surat Masuk', value: 'masuk' },
      { title: 'Memo Subholding', value: 'subholding' },
    ],
    filterValue: {
      memoType: [
        "biasa",
        "bom",
        "izin_prinsip",
        "masuk",
        "subholding",
      ],
    }
  }),
  computed: {
    ...mapState("sent", ["filterParams"]),
    ...mapState("user", ["currentUser"]),
    isPjpDir() {
      if (!this.currentUser) return false;
      return ['PJP', 'PJP_KANPUS', 'DIREKTUR'].includes(this.currentUser.level_aplikasi_elemen);
    },
    selectAllSent: {
      get() {
        return this.$store.state.sent.selectAll;
      },
      set(e) {
        this.$store.commit("sent/SET_SELECT_ALL_SENT", e);
      },
    },
    dialog: {
      get() {
        return this.filterModal
      },
      set(val) {
        this.$emit("filterModal", val);
      },
    },
    filterIsOn() {
      const arrType = this.filterParams.type.split(',');

      if (arrType.length === this.memoTypeList.length) return null;
      return this.iconFilterActive;
    },
  },
  watch: {
    searchQuery(val) {
      if (val && val.length < 3) return;
      let params = this.filterParams;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        params.filter = val ? val : '';
        if (this.$route.name === "sentx") {
          this.loadSentX(params);
        } else {
          this.loadSent(params);
        }
      }, 1000);
    },
  },
  methods: {
    ...mapActions(["setMessageBox"]),
    ...mapActions("sent", ["loadSent", "loadSentX", "selectAll"]),
    sentReload() {
      this.$emit("sentReload");
    },
    selectAllItem(e) {
      this.selectAll(e.target.checked);
    },
    applyQuery() {
      this.showSearch = false;
      if (!this.searchQuery || this.searchQuery == "") return;
      let params = this.filterParams;
      params.filter = this.searchQuery;
      this.loadSent(params);
    },
    revokeSelected() {
      this.$emit("revokeSelected");
    },
    menuAction(slug) {
      if (slug == "reset_filter") {
        this.searchQuery = "";
        // this.setAppMessageBox({
        //   show: true,
        //   type: "error",
        //   title: "error.error-title",
        //   message: "error.network-error",
        // });
      } else {
        this.setMessageBox({
          snackbar: true,
          show: true,
          type: "warning",
          title: "error.warning-title",
          message: "error.this-menu-is-under-construction"
        });
      }
    },
    doFilter() {
      let params = this.filterParams;
      params.type = this.filterValue.memoType.join();

      if (this.$route.name === "sentx") {
        this.loadSentX(params);
      } else {
        this.loadSent(params);
      }

      this.timeout = setTimeout(() => {
        this.dialog = false
      }, 500);
    },
    resetFilter() {
      let params = this.filterParams;
      this.filterValue.memoType = [
        "biasa",
        "bom",
        "izin_prinsip",
        "masuk",
        "subholding",]

      params.type = this.filterValue.memoType.join();
      if (this.$route.name === "sentx") {
        this.loadSentX(params);
      } else {
        this.loadSent(params);
      }

      this.timeout = setTimeout(() => {
        this.dialog = false
      }, 500);
    },
    remove(item) {
      // console.log(this.filterValue.memoType)
      let index = this.filterValue.memoType.indexOf(item.value)
      if (index >= 0) this.filterValue.memoType.splice(index, 1)
    },
  },
};
</script>